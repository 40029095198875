@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@400;500;700&display=swap');
@import '~@fortawesome/fontawesome-free/css/all.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root,
.app {
  height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
}